






import { Component, Vue, Prop } from 'vue-property-decorator'
import { MemuRenderer, MemuRendererDelegate } from '../MemuRenderer'
import { Vector3 } from 'three/src/math/Vector3'

// import variables from '../styles/variables.scss'

@Component
export default class ThreeWebGL extends Vue implements MemuRendererDelegate {
    @Prop() readonly isMobile!: boolean
    @Prop() readonly gradientColors?: Array<Vector3>

    public renderer!: MemuRenderer

    mounted() {
      const renderWrapper = this.$refs.wrapper as HTMLDivElement
      // console.log(renderWrapper)
      this.renderer = new MemuRenderer(this, this.isMobile, renderWrapper, this.gradientColors)

      window.addEventListener('resize', this.onWindowResize, false)
    }

    onWindowResize() {
        this.renderer.onWindowResize(window)
    }

    // ------------------------
    // MemuRendererDelegate

    aRendererEventOccured() {
      console.log('aRendererEventOccured')
    }

    // ------------------------

}
