/*
Author:      
    Nick Cellini
References: 
    https://github.com/Sean-Bradley/Three.js-TypeScript-Boilerplate/blob/statsgui/src/client/client.ts
    https://threejsfundamentals.org/threejs/threejs-shadertoy-basic.html
*/

export interface MemuRendererDelegate {
    aRendererEventOccured(): void;
}

import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Stats from 'three/examples/jsm/libs/stats.module'
import { WEBGL } from 'three/examples/jsm/WebGL'
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module'

// import { OrbitControls } from '/jsm/controls/OrbitControls'
// import Stats from '/jsm/libs/stats.module'
// import {GUI} from '/jsm/libs/dat.gui.module'
import { GUI } from 'dat.gui'

import bgGradient8FragGL1 from './shaders/bg-gradient8_gl1.glsl'
import bgGradient8FragGL2 from './shaders/bg-gradient8_gl2.glsl'
import bgGradient2Frag from './shaders/bg-gradient2.glsl'

const colScheme = [
    new THREE.Vector3(219/255, 226/255, 83/255),    // YELLOW
 // new THREE.Vector3(98/255, 82/255, 162/255),     // PURPLE (TOODARK)
    new THREE.Vector3(198/255, 176/255, 213/255),   // LIGHT PURPLE
    new THREE.Vector3(234/255, 136/255, 47/255),    // ORANGE
    new THREE.Vector3(234/255, 208/255, 207/255),   // PINK
    new THREE.Vector3(114/255, 131/255, 63/255),    // GREEN
    new THREE.Vector3(236/255, 225/255, 188/255),   // PALE YELLOW
    new THREE.Vector3(235/255, 96/255, 43/255),     // DEEP ORANGE
    new THREE.Vector3(59/255, 232/255, 255/255)     // CYAN
]

export class MemuRenderer {
    delegate: MemuRendererDelegate

    scene: THREE.Scene
    backgroundScene: THREE.Scene
    backgroundPlane: THREE.PlaneBufferGeometry
    backgroundCamera: THREE.OrthographicCamera
    // eslint-disable-next-line
    bgUniforms: any
    // camera: THREE.PerspectiveCamera
    renderer: THREE.WebGLRenderer

    gradientColors: Array<THREE.Vector3> = colScheme

    // controls: OrbitControls

    // geometry: THREE.BoxGeometry
    // material: THREE.MeshDepthMaterial

    // cube: THREE.Mesh

    // ------------------
    //      dat.gui
    gui?: GUI
    stats?: Stats
    // ------------------

    constructor(delegate: MemuRendererDelegate, isMobile: boolean, domWrapper: HTMLDivElement, 
        gradientCols?: Array<THREE.Vector3>) {
        
        if (gradientCols != null) {
            this.gradientColors = new Array(gradientCols.length)
            for (let i = 0; i < gradientCols.length; i++) {
                this.gradientColors[i] = gradientCols[i]
            }
        }
        // console.log('MEMU RENDERER gradientCols', this.gradientColors)

        this.delegate = delegate
        const isWebGL2 = WEBGL.isWebGL2Available()
        // console.log('isWebGL2Available: ', isWebGL2)

        this.scene = new THREE.Scene()
        this.backgroundScene = new THREE.Scene()
        this.backgroundCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 0) // left, right, top, bottom, near, far
        // this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 5)
        
        this.renderer = new THREE.WebGLRenderer()
        this.renderer.autoClear = false
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        domWrapper.appendChild(this.renderer.domElement)

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement)

        // - - - - - - - - - - -
        this.backgroundPlane = new THREE.PlaneBufferGeometry(2, 2)

        this.bgUniforms = {
            iTime: { value: 0 },
            iResolution:  { value: new THREE.Vector3() },
            speed: { value: 0.3 },
            center: { value: new THREE.Vector2(0.5, 0.5) },
            circleSize: { value: 1.15 },
            distFromCenter: { value: new THREE.Vector2(0.7, 0.7) },
            colors: { value: this.gradientColors },
        }
        // console.log(bgGradientFrag)


        const fragShader = this.gradientColors.length == 2 ?
            bgGradient2Frag : (isWebGL2 ? bgGradient8FragGL2 : bgGradient8FragGL1)
            

        const params: THREE.ShaderMaterialParameters = {
            fragmentShader: fragShader, uniforms: this.bgUniforms
        }
        const material = new THREE.ShaderMaterial(params)

        this.backgroundScene.add(new THREE.Mesh(this.backgroundPlane, material))

        // this.geometry = new THREE.BoxGeometry()
        // this.material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true })
        // this.material = new THREE.MeshDepthMaterial()
        // this.cube = new THREE.Mesh(this.geometry, this.material)
        // this.cube.rotateX(-Math.PI * 0.35);
        // this.cube.rotateZ(Math.PI * 0.25);

        // this.scene.add(this.cube)
        // this.camera.position.z = 2
        // - - - - - - - - - - -

        // - - - - - - - - - - - - - - - - - - - - - -
        // if (process.env.NODE_ENV !== 'production') {
        //     this.gui = new GUI()
        //     if (this.gui.domElement.parentElement != null) {
        //         domWrapper.appendChild(this.gui.domElement.parentElement)
        //     }
        //     this.setupDatGUI()
        //     if (process.env.NODE_ENV === 'staging') { 
        //         this.gui.hide()

        //     }
        //     this.stats = Stats()
        //     domWrapper.appendChild(this.stats.dom)
        // }
        // - - - - - - - - - - - - - - - - - - - - - -

        requestAnimationFrame(this.update.bind(this))
    }

    update(time: number) {

        // this.controls.update()

        this.render(time * 0.001)

        this.stats?.update()
        
        requestAnimationFrame(this.update.bind(this))
    }

    render(time: number) {
        const canvas = this.renderer.domElement
        this.bgUniforms.iResolution.value.set(canvas.width, canvas.height, 1)
        this.bgUniforms.iTime.value = time

        // this.renderer.clear()
        this.renderer.render(this.backgroundScene, this.backgroundCamera)
        // this.renderer.render(this.scene, this.camera)
    }

    //  -----------------------------------------------

    //  ####### #     # ####### #     # #######  #####  
    //  #       #     # #       ##    #    #    #     # 
    //  #       #     # #       # #   #    #    #       
    //  #####   #     # #####   #  #  #    #     #####  
    //  #        #   #  #       #   # #    #          # 
    //  #         # #   #       #    ##    #    #     # 
    //  #######    #    ####### #     #    #     #####  

    onWindowResize(win: Window) {
        const canvas = this.renderer.domElement
        const width = win.innerWidth //canvas.clientWidth
        const height = win.innerHeight //canvas.clientHeight
        // console.log(win)

        const needResize = canvas.width !== width || canvas.height !== height
        if (needResize) {
            // this.camera.aspect = width / height
            // this.camera.updateProjectionMatrix()
            
            this.renderer.setSize(width, height, true) // updateStyle: false
        }

        // this.render()
    }

    //  -----------------------------------------------

    setupDatGUI() {
        if (this.gui == null || process.env.NODE_ENV === 'production') { return }

        const cubeFolder = this.gui.addFolder("BG Gradient")
        // cubeFolder.add(this.material, "displacementScale", 0, Math.PI * 2, 0.01)
        // cubeFolder.add(this.material, "displacementScale", -100, 100, 0.01)
        // cubeFolder.add(this.material, "displacementBias", -100, 100, 0.01)
        cubeFolder.add(this.bgUniforms.speed, "value", 0.1, 32, 0.1).name("Speed")
        cubeFolder.add(this.bgUniforms.distFromCenter.value, "x", 0, 1.2, 0.01).name("X Dist from Center")
        cubeFolder.add(this.bgUniforms.distFromCenter.value, "y", 0, 1.2, 0.01).name("Y Dist from Center")
        cubeFolder.add(this.bgUniforms.circleSize, "value", 0.5, 1.4, 0.01).name("Circle Scale")
        // cubeFolder
        //     .add(this.bgUniforms.distFromCenter, "value", 0.5, 64, 1)

        cubeFolder.open()
        // const cameraFolder = this.gui.addFolder("Camera")
        // cameraFolder.add(this.camera.position, "z", 0, 10, 0.01)
        // cameraFolder.open()
    }

}