














































































































































































import firebase from 'firebase/app'
import 'firebase/analytics'
// import 'firebase/functions'
import 'firebase/functions'

import { default as channels } from '../shared/channel-configs/Channels'

import { Component, Vue } from 'vue-property-decorator'
import ThreeWebGL from '@/views/ThreeWebGL.vue'

import isMobile from "is-mobile"
import { AudioPlayerState, AudioPlayerUIResponder, AudioPlayer } from '../shared/AudioPlayer'
// import { Copy as MEMUCopy } from '../shared/MEMU/copywriting-data'
import Ticker from './components/Ticker.vue'
import { ChannelConfig } from '../shared/channel-configs/ChannelConfig'
import { Vector3 } from 'three'
// import { ScrollLock } from './ScrollLock'

// import { Component as ComponentType } from 'vue'

// import variables from './styles/variables.scss'

// ------------------------------------------------

const isMob = isMobile()
// console.log('isMobile:', isMob)

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFIgBJASPdfyYcemm5ixdBmjFfHYo2YCM",
  authDomain: "uv-memu.firebaseapp.com",
  projectId: "uv-memu",
  storageBucket: "uv-memu.appspot.com",
  messagingSenderId: "851534554818",
  appId: "1:851534554818:web:592a1edb5730d9c1d5fd5b",
  measurementId: "G-ZJ46CTQ79F"
}

// ------------------------------------------------

@Component({ components: { ThreeWebGL, Ticker } })
export default class App extends Vue implements AudioPlayerUIResponder {

  firebaseApp: firebase.app.App | null = null
  firebaseFunctions: firebase.functions.Functions | null = null

  isMobileDevice = isMob
  // private heroIsVisible = true
  channelInfoVisible = false

  // - - - - - - - - - - - - - - - - - -   
  // CUSTOM CHANNEL DATA
  private channelConfig: ChannelConfig = channels[0]
  channelIsAuthenticated = true
  
  passwordInput = ""
  showPasswordError = false
  passwordReqIsLoading = false

  get channelListFiltered() {
    return channels.filter((el) => el.showInChannelList);
  }

  get playerURL(): string {
    return process.env.NODE_ENV == 'development' && this.channelConfig.streamURLDev != null 
          ? this.channelConfig.streamURLDev : this.channelConfig.streamURL
  }
  get channelMetadataURL(): string {
    return this.channelConfig.metadataURL
  }
  get channelTickerSeparator(): string {
    return this.channelConfig.tickerSeparator
  }
  get bgGradientColors(): Array<Vector3> | undefined {
    return this.channelConfig.colorsGradient2
  }
  get ColorMain(): string {
    if (this.channelConfig.colorsGradient2 == null) return `white`
    const colArr = this.channelConfig.colorsGradient2
    const col1 = colArr[0] as Vector3;
    return `rgb(${col1.x * 255} ${col1.y * 255}, ${col1.z * 255})`
  }
  get ColorAlt(): string {
    if (this.channelConfig.colorsGradient2 == null) return `black`
    const colArr = this.channelConfig.colorsGradient2
    const col2 = colArr[1] as Vector3;
    return `rgb(${col2.x * 255}, ${col2.y * 255}, ${col2.z * 255})`
  }
  get channelName(): string | undefined {
    return this.channelConfig.name
  }
  get channelDescription(): string {
    return this.channelConfig.attributionDescription
  }
  get channelLogoSVGURL(): string | undefined {
    return this.channelConfig.attributionLogoSVGURL
  }
  get channelLogoSVGBGStyle(): string | undefined {
    return 'url('+this.channelConfig.attributionLogoSVGURL+') left center no-repeat'
  }
  get channelLogoPixelHeight(): number { return 68; }
  get channelLogoPixelWidth(): number | undefined {
    return Math.ceil((this.channelConfig.logoAspectRatio ?? 2) * this.channelLogoPixelHeight)
  }
  // private playerURL = "https://memustream.live:8443/uv_site_live_20" // PRODUCTION by DEFAULT

  // - - - - - - - - - - - - - - - - - -

  private memuPlayer!: AudioPlayer
  botLeftBtnState: AudioPlayerState = AudioPlayerState.Play
  // private get audioElement(): HTMLAudioElement { return this.$refs.audiostream as HTMLAudioElement }

  flashPlayButton = true
  private _ticker = this.$refs.ticker as Ticker | undefined

  private _shapes!: Array<HTMLElement>
  static MAX_SCALE_MAG = 0.1
  private _shapeScaleMagnitude = 0

  // -----------------------
  // static framerate = 60
  static framerateFraction = 1/60 * 1000
  // -----------------------

  setupCustomChannel() {
    const path = window.location.pathname.replaceAll('/','')

    let selChannel = channels[0]
    if (path.length < 1) { return }
    for (let i = 1; i < channels.length; ++i) {
      const config = channels[i]
      if (config.urlSlug == path) {
        selChannel = config
        break
      }
    } // ------------------------------
    this.channelConfig = selChannel
    this.channelIsAuthenticated = !selChannel.passwordProtected
    // console.log('CUSTOM CHANNEL', this.channelConfig.name)
  }

  created() {
    this.setupCustomChannel()

    
    document.addEventListener('DOMContentLoaded', this.domContentLoaded.bind(this))
    window.onload = this.documentLoaded
    // window.onpopstate = this.onPopState

    this._shapes = []
    this._shapeScaleMagnitude = 0
  }

  // destroyed() {
    
  // }

  documentLoaded() {
    // console.log('documentLoaded')
    // document.body.classList.add('uv-finished-loading')
    // this._ticker?.setupTickerWrapper()

    // if (this.channelConfig.id != null) {
    //   this.getSecureChannelInfo(this.channelConfig.id, "<PASSWORD HERE>")
    // }
    
  }

  domContentLoaded() {
    // console.log('app.vue::domContentLoaded')

    this._ticker?.setupTickerWrapper()

    // const isContentURL = tabDataDesktopArray
    //   .findIndex((tabDat: TabData) => tabDat.route == document.location.pathname) >= 0

    // The url path is /projects, /about or /team
    // if (isContentURL) {
    //   this.scrollFullpageDown()
    // }

    // =============================
    // INIT ANALYTICS
    if (this.firebaseApp == null) {
      this.firebaseApp = firebase.initializeApp(firebaseConfig)
    }
    if (this.firebaseApp == null) {
      // console.error('Firebase App NOT initialised')
      return
    }

    this.firebaseApp.analytics()

    const isDev = process.env.NODE_ENV == 'development'
    this.firebaseFunctions = this.firebaseApp.functions('asia-northeast1')
    if (isDev) {
      this.firebaseFunctions.useEmulator('localhost', 5001)
    }    

    setTimeout(() => {
      requestAnimationFrame(this.updateCallback.bind(this))
      this._ticker?.setupTickerWrapper()
    }, 600)
  }

  getSecureChannelInfo(
    channelName: string, 
    password: string, 
    resultCallback: (valid: boolean) => void) {

    // console.log('getSecureChannelInfo...')

    const functions = this.firebaseFunctions
    if (!functions) { return }
    const getChannelInfo = functions.httpsCallable('getChannelInfo')
    // ------------------------------------------
    getChannelInfo({ channelName: channelName, password: password })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const errStatus = result.data['status']
        if (errStatus != null && errStatus == 'error') {
          resultCallback(false)
          return
        }

        const returnedChannelID = result.data['channelName']

        // console.log(result)
        if (this.channelConfig.id == returnedChannelID) {
          this.channelConfig.metadataURL = result.data['metadataURL']
          this.channelConfig.streamURL = result.data['streamURL']
          this.channelIsAuthenticated = true
          resultCallback(true)
        } else {
          resultCallback(false)
        }

        // const data = result.data;
        // const sanitizedMessage = data.text;
      })
      .catch(() => {
        // Getting the Error details.
        // const code = error.code;
        // const message = error.message;
        // const details = error.details;
        // ...
        // console.log('ERR', code)
        resultCallback(false)
      })
  }

  mounted() {

    this._shapes.push(this.$refs.shape1 as HTMLElement)
    this._shapes.push(this.$refs.shape2 as HTMLElement)
    this._shapes.push(this.$refs.shape3 as HTMLElement)
    this._shapes.push(this.$refs.shape4 as HTMLElement)
    this._shapes.push(this.$refs.shape5 as HTMLElement)
    this._shapes.push(this.$refs.shape6 as HTMLElement)
    this._shapes.push(this.$refs.shape7 as HTMLElement)
    this._shapes.push(this.$refs.shape8 as HTMLElement)

    this._ticker = this.$refs.ticker as Ticker | undefined
    // this.setupTickerWrapper()

    this.memuPlayer = new AudioPlayer({
      audioElement: this.$refs.audiostream as HTMLAudioElement,
      uiResponder: this, 
      continueStreamingOnPause: true
    })
    this.botLeftBtnState = this.memuPlayer.stateVisual
    
    // this.memuPlayer.debug = true
    this.memuPlayer.debug = (process.env.NODE_ENV == 'development')

    window.onkeydown = this.keyDownCallback.bind(this)
  }

  showChannelInfoWindowBtnClicked() {
    this.channelInfoVisible = true

    if (!this.channelListIsOpen) return
    this.channelListIsOpen = false
    this.channelListIsOpenChanged()
  }

  closeChannelInfoWindowBtnClicked() {
    this.channelInfoVisible = false
  }

  submitPasswordClicked() {
    if (this.channelConfig.id != null) {

      this.passwordReqIsLoading = true
      this.getSecureChannelInfo(this.channelConfig.id, this.passwordInput, 
      (passwordIsValid) => {
        this.showPasswordError = !passwordIsValid
        this.passwordReqIsLoading = false


      })
    }
  }

  //   #####  #     #    #    #     # #     # ####### #          #       ###  #####  ####### 
  //  #     # #     #   # #   ##    # ##    # #       #          #        #  #     #    #    
  //  #       #     #  #   #  # #   # # #   # #       #          #        #  #          #    
  //  #       ####### #     # #  #  # #  #  # #####   #          #        #   #####     #    
  //  #       #     # ####### #   # # #   # # #       #          #        #        #    #    
  //  #     # #     # #     # #    ## #    ## #       #          #        #  #     #    #    
  //   #####  #     # #     # #     # #     # ####### #######    ####### ###  #####     #    

  channelListIsOpen = false

  exploreChannelsBtnClicked() {
    this.channelListIsOpen = !this.channelListIsOpen
    this.channelListIsOpenChanged()
  }

  channelListIsOpenChanged() {
    if (this.channelListIsOpen) {
      const channelListEl = this.$refs.channelList as Element
      this.$emit('channel-list-height-change-event', 
                  channelListEl.scrollHeight);
    } else {
      this.$emit('channel-list-height-change-event', 0);
    }
  }

  // ---------------------------------------------------
  
  //  #     # ######  ######     #    ####### ####### 
  //  #     # #     # #     #   # #      #    #       
  //  #     # #     # #     #  #   #     #    #       
  //  #     # ######  #     # #     #    #    #####   
  //  #     # #       #     # #######    #    #       
  //  #     # #       #     # #     #    #    #       
  //   #####  #       ######  #     #    #    ####### 


  // https://codepen.io/ma77os/pen/KGIEh
  lerp (start: number, end: number, amt: number) {
      const m = (1-amt)
      return m*start+amt*end
  }

  lastUpdateTime?: number

  foo(num: number, time: number) {
    return Math.round((1 - (this._shapeScaleMagnitude * (2 + Math.sin(time * num)) * 0.333)) * 1000) / 1000
  }

  updateMemuShapeDance(time: number, dt: number) {
    /*
    const height = 64;
    // 28, 36, 42, 52, 66, 73, 78, 85
    (this.$refs.shape1 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0073)) +'px, 0)';
    (this.$refs.shape2 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0036)) +'px, 0)';
    (this.$refs.shape3 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0042)) +'px, 0)';
    (this.$refs.shape4 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0066)) +'px, 0)';
    (this.$refs.shape5 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0085)) +'px, 0)';
    (this.$refs.shape6 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0052)) +'px, 0)';
    (this.$refs.shape7 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0028)) +'px, 0)';
    (this.$refs.shape8 as HTMLElement).style.transform = 'translate3d(0, '+ Math.min(0, -height * Math.sin(time * 0.0078)) +'px, 0)';
    */

    if (this._shapeScaleMagnitude < App.MAX_SCALE_MAG) {
      this._shapeScaleMagnitude = Math.min(dt*0.00001 + this._shapeScaleMagnitude, App.MAX_SCALE_MAG)
    }
    // console.log(this._shapeScaleMagnitude)

    let val = this.foo(0.0021, time)
    this._shapes[0].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0036, time)
    this._shapes[1].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0042, time)
    this._shapes[2].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0019, time)
    this._shapes[3].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0031, time)
    this._shapes[4].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0012, time)
    this._shapes[5].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0028, time)
    this._shapes[6].style.transform = 'scale3d('+val+','+val+',1)';
    val = this.foo(0.0035, time)
    this._shapes[7].style.transform = 'scale3d('+val+','+val+',1)';
  }

  updateCallback(timestamp: number) {
    requestAnimationFrame(this.updateCallback.bind(this))
    const dt = (this.lastUpdateTime === undefined) ? App.framerateFraction : timestamp - this.lastUpdateTime
    
    if (process.env.NODE_ENV != 'development') {
      this.updateMemuShapeDance(timestamp, dt)
    }

    this._ticker?.update(dt)

    // const newYTrans = this.lerp (this.memuShapesY, this.targetYTranslate, amount)
    this.lastUpdateTime = timestamp
  }
  // ---------------

  // ==============================
  //     #    #     # ######  ### #######    ######  #          #    #     # ####### ######  
  //    # #   #     # #     #  #  #     #    #     # #         # #    #   #  #       #     # 
  //   #   #  #     # #     #  #  #     #    #     # #        #   #    # #   #       #     # 
  //  #     # #     # #     #  #  #     #    ######  #       #     #    #    #####   ######  
  //  ####### #     # #     #  #  #     #    #       #       #######    #    #       #   #   
  //  #     # #     # #     #  #  #     #    #       #       #     #    #    #       #    #  
  //  #     #  #####  ######  ### #######    #       ####### #     #    #    ####### #     # 

  audioPlayerStateChanged(state: AudioPlayerState, uiState: AudioPlayerState) {
    // const stateStr = AudioPlayer.stringFromPlayerState(state)
    // const uiStateStr = AudioPlayer.stringFromPlayerState(state)

    // console.log('audioPlayerStateChanged:', stateStr, ', UI State:', uiStateStr)
    this.botLeftBtnState = uiState
  }

  audioPlayerHadAnError(errStr: string) {
    // console.log('audioPlayerHadAnError', errStr)

    const analytics = this.firebaseApp?.analytics()
    if (!analytics) { return }
    analytics.logEvent('audio_player_error', { 'err_type': errStr })
  }

  // ==============================
  //  ######  #          #    #     #          #    ######     #    #     #  #####  ####### 
  //  #     # #         # #    #   #          #     #     #   # #   #     # #     # #       
  //  #     # #        #   #    # #          #      #     #  #   #  #     # #       #       
  //  ######  #       #     #    #          #       ######  #     # #     #  #####  #####   
  //  #       #       #######    #         #        #       ####### #     #       # #       
  //  #       #       #     #    #        #         #       #     # #     # #     # #       
  //  #       ####### #     #    #       #          #       #     #  #####   #####  ####### 


  playPauseBtnClicked() {
    // console.log('playPauseBtnClicked')
    if (this.memuPlayer.state == AudioPlayerState.Loading) { return }

    let eventName = "play_clicked"
    const wasFirst = this.flashPlayButton

    if (this.memuPlayer.state == AudioPlayerState.Pause) { // PLAY
      this.memuPlayer.play()
    } else if (this.memuPlayer.state == AudioPlayerState.Play) { // PAUSE
      this.memuPlayer.pause()
      this.flashPlayButton = false
      eventName = "pause_clicked"
    }
    // console.log('this.botLeftBtnMode', this.botLeftBtnMode)

    const analytics = this.firebaseApp?.analytics()
    if (!analytics) { return }
    analytics.logEvent(eventName, { 'first_click': wasFirst })
  }

  // ==============================
  //  #    # ####### #     # ######  #######    #    ######  ######     ####### #     # ####### #     # #######  #####  
  //  #   #  #        #   #  #     # #     #   # #   #     # #     #    #       #     # #       ##    #    #    #     # 
  //  #  #   #         # #   #     # #     #  #   #  #     # #     #    #       #     # #       # #   #    #    #       
  //  ###    #####      #    ######  #     # #     # ######  #     #    #####   #     # #####   #  #  #    #     #####  
  //  #  #   #          #    #     # #     # ####### #   #   #     #    #        #   #  #       #   # #    #          # 
  //  #   #  #          #    #     # #     # #     # #    #  #     #    #         # #   #       #    ##    #    #     # 
  //  #    # #######    #    ######  ####### #     # #     # ######     #######    #    ####### #     #    #     #####  
                                                                                                                   

  keyDownCallback(evt: KeyboardEvent) {
    if (evt.metaKey) { return }

    const lowerCode = evt.code.toLowerCase()
    if (lowerCode == 'space') {
      this.playPauseBtnClicked()
    } else {
      // this._ticker?.moveToNextConfig()
    }
  }

  // ======================================================================
  // ======================================================================
  // ======================================================================
  // ======================================================================

  //   #####  ####### #     # #       ####### 
  //  #     #    #     #   #  #       #       
  //  #          #      # #   #       #       
  //   #####     #       #    #       #####   
  //        #    #       #    #       #       
  //  #     #    #       #    #       #       
  //   #####     #       #    ####### ####### 

  // VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV
}

