

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// import { Copy as MEMUCopy } from '../../shared/MEMU/copywriting-data'
// import { Color as MEMUColor } from '../../shared/MEMU'

interface TapeDat {
    xTranslate: number;
    yTranslate: number;
    innerLengthWidth: number;
}

interface StreamConfig {
    name: string;
    tempo: number;
    chroma: number;
    axisComplex: number;
    axisHeat: number;
    source: Array<string>;
}

@Component
export default class Ticker extends Vue {
    @Prop() readonly isMobile!: boolean
    @Prop() readonly streamMetadataURL!: string
    // static streamMetaURL = "https://memustream.live/memu/meta.json"
    @Prop() readonly tickerSeparator!: string
    
    private tickingIsActive = false

    private tape1IsActive = true // false implies tape2 is active
    private tapesTransitioning = false
    private transitionAnimTime = 0

    // - - - - - - - - - - - - - - - - - - - - - - - - 


    // - - - - - - - - - - - - - - - - - - - - - - - -
    static MAX_SPEED = 0.05
    private tickerSpeed = 0

    private lastConfigUpdateDate: Date | null = null
    private streamConfig: StreamConfig | null = null
    private updateTickerIntervalID: number | null = null

    private get tickerDiv(): HTMLDivElement { return this.$refs.tickerwrapper as HTMLDivElement }
    // private get tape1(): HTMLDivElement { return this.$refs.tape1 as HTMLDivElement }
    // private get tape2(): HTMLDivElement { return this.$refs.tape2 as HTMLDivElement }

    private tape1Dat: TapeDat = { xTranslate: 0, yTranslate: 0, innerLengthWidth: 0 }
    private tape2Dat: TapeDat = { xTranslate: 0, yTranslate: -56, innerLengthWidth: 0 }
    
    tape1Text = ""
    @Watch('tape1Text', { immediate: true }) 
    tape1TextChanged() {
        const tape1 = this.$refs.tape1 as HTMLDivElement | undefined
        if (tape1 == null) { return }

        setTimeout(() => {
            const innerLength1 = tape1.firstElementChild as HTMLSpanElement | null
            if (innerLength1 == null) { return }
            this.tape1Dat.innerLengthWidth = innerLength1.getBoundingClientRect().width
            Ticker.updateTapeTransform(this.tape1Dat, tape1)
            // console.log(this.tape1Dat.innerLengthWidth)
        })
    }
    tape2Text = ""
    @Watch('tape2Text', { immediate: true }) 
    tape2TextChanged() {
        const tape2 = this.$refs.tape2 as HTMLDivElement | undefined
        if (tape2 == null) { return }
        
        setTimeout(() => {
            const innerLength2 = tape2.firstElementChild as HTMLSpanElement | null
            if (innerLength2 == null) { return }
            this.tape2Dat.innerLengthWidth = innerLength2.getBoundingClientRect().width
            Ticker.updateTapeTransform(this.tape2Dat, tape2)
            // console.log(this.tape2Dat.innerLengthWidth)
        })
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - 

    // ---------------
    
    // private boundWindowResizeFunc: (() => void) | null = null
    // private boundScrollFunc: ((evt: Event) => void) | null = null

    created () {
        // this.boundWindowResizeFunc = this.windowResized.bind(this)
        // this.boundScrollFunc = this.handleScroll.bind(this)

        // window.addEventListener('resize', this.boundWindowResizeFunc)
        // window.addEventListener('scroll', this.boundScrollFunc)

    }
    mounted () {
        // console.log('mounted')
        // console.log('window.innerHeight: ', window.innerHeight)
        this.setupTickerWrapper()
        // setTimeout(() => {
        //     const innerLength = this.tape1.firstElementChild as HTMLSpanElement
        //     console.log(innerLength.getBoundingClientRect().width)
        // }, 300)

        this.checkForLatestConfigData()
        this.startCheckingForConfigData()

        // const tape1 = this.$refs.tape1 as HTMLDivElement | undefined
        // console.log(this.$refs.tape1)
        // console.log(tape1)
    }
    destroyed () {
        // if (this.boundWindowResizeFunc) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
        // if (this.boundScrollFunc) { window.removeEventListener('scroll', this.boundScrollFunc) }

        this.stopCheckingForConfigData()
    }

    // ---------------------------------------------------

    setupTickerWrapper() {
        const tape1 = this.$refs.tape1 as HTMLDivElement | undefined
        const tape2 = this.$refs.tape2 as HTMLDivElement | undefined
        if (tape1 == null || tape2 == null) { return }

        const innerLength1 = tape1.firstElementChild as HTMLSpanElement | null
        const innerLength2 = tape2.firstElementChild as HTMLSpanElement | null

        if (innerLength1) {
            this.tape1Dat.innerLengthWidth = innerLength1.getBoundingClientRect().width
        }
        if (innerLength2) {
            this.tape2Dat.innerLengthWidth = innerLength2.getBoundingClientRect().width
        }
        // console.log(this.tape1Dat.innerLengthWidth)

        Ticker.updateTapeTransform(this.tape1Dat, tape1)
        Ticker.updateTapeTransform(this.tape2Dat, tape2)
    }

    private moveToNextConfig(config: StreamConfig) {
        if (this.tapesTransitioning) { return }

        if (this.tape1Text.length < 1) {
            this.tape1Text = Ticker.tickerStringFromConfig(config, this.tickerSeparator)
            return

        } else {
            if (this.tape1IsActive) {
                this.tape2Text = Ticker.tickerStringFromConfig(config, this.tickerSeparator)
            } else {
                this.tape1Text = Ticker.tickerStringFromConfig(config, this.tickerSeparator)
            }
            this.tape1IsActive = !this.tape1IsActive
        }
        
        const startX = 48
        if (this.tape1IsActive) {
            this.tape1Dat.xTranslate = startX
            Ticker.updateTapeTransform(this.tape1Dat, this.$refs.tape1 as HTMLDivElement | undefined)
        } else {
            this.tape2Dat.xTranslate = startX
            Ticker.updateTapeTransform(this.tape2Dat, this.$refs.tape2 as HTMLDivElement | undefined)
        }
        
        this.tapesTransitioning = true
        this.transitionAnimTime = 0
    }

    // ---------------------------------------------------

    //  #     # ####### ####### ######  
    //  #     #    #       #    #     # 
    //  #     #    #       #    #     # 
    //  #######    #       #    ######  
    //  #     #    #       #    #       
    //  #     #    #       #    #       
    //  #     #    #       #    #       

    startCheckingForConfigData() {
        if (this.updateTickerIntervalID != null) { return }

        // 10000 = 10secs
        this.updateTickerIntervalID = setInterval (this.checkForLatestConfigData.bind(this), 5000)
    }
    stopCheckingForConfigData() {
        if (this.updateTickerIntervalID == null) { return }

        clearInterval(this.updateTickerIntervalID)
        this.updateTickerIntervalID = null
    }

    static tickerStringFromConfig(config: StreamConfig, separator: string): string {
        let str = ""
        for (let i = 0; i < config.source.length; i++) {
            const el = config.source[i]
            const data = el.split('-by-')

            str += "<b>"+ data[0] + "</b> "+ separator + data[1] + " : "
        }
        return str
    }

    checkForLatestConfigData() {
        // console.log('checkForLatestConfigData')
        this.getLatestConfig((config: StreamConfig | null | string, lastModifiedDate?: Date) => {
            if (config == null || lastModifiedDate == null) { return }
            if (typeof config === 'string' || config instanceof String) {
                // ERROR
                // console.error(config as string)
            } else {
                this.streamConfig = config
                this.lastConfigUpdateDate = lastModifiedDate
                // console.log('GOT A NEW CONFIG... date: ', this.lastConfigUpdateDate)

                this.moveToNextConfig(config)
            }

            // TODO: IS THIS NECESSARY?
            setTimeout(() => {
                this.tickingIsActive = true
            }, 900)
        })
    }

    getLastModifiedDate(): Promise<Date | null> {
        return new Promise((resolve) => {
            if (this.lastConfigUpdateDate == null) { resolve(null) }

            const headREQ = new XMLHttpRequest()
            // eslint-disable-next-line
            headREQ.onreadystatechange = (function ( request: XMLHttpRequest): any | null {
                if (request.readyState === XMLHttpRequest.DONE) {
                    if (request.status === 200) {
                        // const headers = request.getAllResponseHeaders()
                        const lastModifiedDateStr = request.getResponseHeader('last-modified')
                        if (lastModifiedDateStr == null) { 
                            resolve(null)
                            return
                        }
                        const date = new Date(lastModifiedDateStr)
                        resolve(date)
                    }
                }
            }).bind(this, headREQ)
            headREQ.open("HEAD", this.streamMetadataURL, true); // true for asynchronous 
            headREQ.send(null);
        })
    }

    // Calls completeCallback(null) if the config hasn't changed
    getLatestConfig( completeCallback: (config: StreamConfig | null | string, lastModifiedDate?: Date) => void) {

        if (this.streamMetadataURL.length < 1) { return }

        // Do a HEAD request
        this.getLastModifiedDate()
        .then((lastModifiedDate: Date | null) => {
            if (this.lastConfigUpdateDate != null && lastModifiedDate != null 
                && lastModifiedDate.getTime() == this.lastConfigUpdateDate.getTime()) { 
                // console.log('Already have the latest config data')
                return
            }

            // GET NEW DATA!
            const req = new XMLHttpRequest()
            // eslint-disable-next-line
            req.onreadystatechange = (function ( request: XMLHttpRequest ): any | null {
                // console.log(request)
                if (request.readyState === XMLHttpRequest.DONE) {
                    if (request.status === 200) {
                        const dict = JSON.parse(request.responseText)
                        const globals = dict['globals']
                        const streamConfig: StreamConfig = {
                            name: dict['name'] ?? "default",
                            tempo: globals ? (globals['tempo'] ?? 110) : 110,
                            chroma: globals ? (globals['chroma'] ?? 4) : 4,
                            axisComplex: globals ? (globals['axis-complex'] ?? 0) : 0,
                            axisHeat: globals ? (globals['axis-heat'] ?? 0) : 0,
                            source: dict['source'] ?? [],
                        }
                        
                        const lastModifiedDateStr = request.getResponseHeader('last-modified')
                        const lastModifiedDate = lastModifiedDateStr ? new Date(lastModifiedDateStr) : new Date()

                        completeCallback(streamConfig, lastModifiedDate)
                        // console.log(streamConfig)
                        return
                    } else {
                        completeCallback('getLatestConfig ERROR')
                    }
                }
            }).bind(this, req)
            req.open("GET", this.streamMetadataURL, true); // true for asynchronous 
            req.send(null);
        })
    }

    // ---------------------------------------------------
    
    //  #     # ######  ######     #    ####### ####### 
    //  #     # #     # #     #   # #      #    #       
    //  #     # #     # #     #  #   #     #    #       
    //  #     # ######  #     # #     #    #    #####   
    //  #     # #       #     # #######    #    #       
    //  #     # #       #     # #     #    #    #       
    //   #####  #       ######  #     #    #    ####### 


    // https://codepen.io/ma77os/pen/KGIEh
    // static lerp (start: number, end: number, amt: number) {
    //     const m = (1-amt)
    //     return m*start+amt*end
    // }
    static easeInOutQuad(t: number): number {
        return t <.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    }

    lastUpdateTime?: number

    static updateTape(dt: number, speed: number, tapeDat: TapeDat, tapeDiv: HTMLDivElement | undefined, animateIn: boolean, transitionAnimTime: number | null) {
        tapeDat.xTranslate -= dt * speed
        // console.log(this.tape1Dat.innerLengthWidth)
        if (tapeDat.xTranslate < -tapeDat.innerLengthWidth) {
            tapeDat.xTranslate += tapeDat.innerLengthWidth
        }

        if (transitionAnimTime != null) {
            const o = animateIn ? -56 : 0
            const d = animateIn ? 0 : 56

            const newYTrans = ((1-transitionAnimTime) * o) + (transitionAnimTime * d)
            // console.log(transitionAnimTime)
            tapeDat.yTranslate = newYTrans
        }

        this.updateTapeTransform(tapeDat, tapeDiv)
    }

    static updateTapeTransform(tapeDat: TapeDat, tapeDiv: HTMLDivElement | undefined) {
        // console.log(tapeDiv)
        if (tapeDiv == null) { return }
        tapeDiv.style.transform = "translate3d("+ tapeDat.xTranslate +"px, "
                                 + tapeDat.yTranslate +"px, 0)"
    }

    update(dt: number) {
        
        // - - - - - - - - - - - -
        let animPercent: number | null = null
        let animSpeed = this.tickingIsActive ? Ticker.MAX_SPEED : 0
        if (this.tapesTransitioning) {
            this.transitionAnimTime = Math.min(1, dt * 0.001 + this.transitionAnimTime)
            animPercent = Ticker.easeInOutQuad(this.transitionAnimTime)
        }

        if (this.tickingIsActive && this.tickerSpeed < 1 
            && ((this.tape1IsActive && this.tape1Text.length > 0) 
                || (!this.tape1IsActive && this.tape2Text.length > 0))) {

            this.tickerSpeed = Math.min(1, dt * 0.001 + this.tickerSpeed)
            animSpeed = Ticker.MAX_SPEED * Ticker.easeInOutQuad(this.tickerSpeed)
            // console.log(animSpeed)
        }
        
        // - - - - - - - - - - - -
        
        const tape1 = this.$refs.tape1 as HTMLDivElement | undefined
        const tape2 = this.$refs.tape2 as HTMLDivElement | undefined
        if (tape1 == null || tape2 == null) { return }

        // console.log(this.tapesTransitioning)
        if (this.tapesTransitioning || this.tape1IsActive) { 
            Ticker.updateTape(dt, animSpeed, this.tape1Dat, tape1, this.tape1IsActive, animPercent)
        }
        if (this.tapesTransitioning || !this.tape1IsActive) { 
            Ticker.updateTape(dt, animSpeed, this.tape2Dat, tape2, !this.tape1IsActive, animPercent)
        }

        if (this.tapesTransitioning && this.transitionAnimTime > 0.999) {
            
            this.tape1Dat.yTranslate = this.tape1IsActive ? 0 : -56
            this.tape2Dat.yTranslate = !this.tape1IsActive ? 0 : -56
            Ticker.updateTapeTransform(this.tape1Dat, tape1)
            Ticker.updateTapeTransform(this.tape2Dat, tape2)

            this.tapesTransitioning = false
        }
    }
    // ---------------------------------------------------

}
