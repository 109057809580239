import { ChannelConfig } from "./ChannelConfig"
import { Vector3 } from 'three/src/math/Vector3'

const sleepChannel: ChannelConfig = {
    showInChannelList: true,
    passwordProtected: false,
    id: "sleep",
    
    tickerSeparator: "by",
    streamURL: "https://memustream.live:8443/uv_site_live_20",
    streamURLDev: "https://memustream.live:8443/uv_site_live_20",
    metadataURL: "https://memustream.live/memu/meta.json",

    name: "Sleep",
    channelListName: "SLEEP",
    urlSlug: "sleep",
    attributionDescription: "MEMU channels generate music live using our AI music engine and sounds provided by our collaborators, inputs are fully trackable! If you're interested in creating your own customised MEMU channel, get in touch.",
    colorsGradient2: [ 
        new Vector3(151/255, 219/255, 243/255), 
        new Vector3(190/255, 233/255, 237/255)
    ]
}

export default sleepChannel